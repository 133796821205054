import * as React from 'react';
import { Component } from 'react';
import { CookiesProvider, withCookies } from 'react-cookie';
import {
  Button,
  Col,
  Container,
  Fa,
  Input,
  Row,
  Mask,
  Modal,
  ModalHeader,
  ModalBody,
} from 'mdbreact';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {v4} from 'uuid';
import formDifferentiation from './FormType.js';
import './Trees.css';
import imageCompression from 'browser-image-compression';
import moment from 'moment';
// import AdCookie from '../Utilities/AdCookie.js';
import Node from './sections/Node';

const trees = {
  veccheckin: require('./sections/VECCheckin'),
  vecemployeevideo: require('./sections/VECEmployeeVideo'),
};

/*
To do:
* Pass in the locations information as a URL parameter.
*/

class TreeRenderer extends Component {
  constructor(props) {
    super(props);
    const startState = [
      props.tree
      // this.props.location.pathname.substring(1, this.props.location.pathname.length),
    ];
    //   locationName:
    //   (this.props.location &&
    //     this.props.location.state &&
    //     this.props.location.state.aerLocationName) ||
    //   null,
    // location:
    //   (this.props.location &&
    //     this.props.location.state &&
    //     this.props.location.state.aerLocationUUID) ||
    //   null,
    // console.log(this.state)
    // this.state.locationId = "queryString.parse(this.props.location.search).LUUID";
    this.state = trees[startState];
    this.state.startingTree = startState;
    this.state.modal = false;
    this.state.waitingOnBackEndResponse = false;
    this.getNextNode = this.getNextNode.bind(this);
    this.setCook = this.setCook.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.buttonRender = this.buttonRender.bind(this);
    this.formRender = this.formRender.bind(this);
    this.onFormInputChange = this.onFormInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.state.buttonsInContent = [];    
    // this.processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    // this.htmlToReactParser = new HtmlToReactParser();
  }

  componentDidMount() {
    window.scroll(0, 0);
    const locationJson = {"ro":"dad6886d-68a9-435d-97ed-2806fd5333f1", "ka":"8279eca9-c9f7-401b-ac34-e0d29b3865bb", "jv":"4e3193c2-89b5-4eee-8a44-5735edd1b926", "cc":"cc649dc4-31e4-45d2-a8bd-32e50464995d"}
    // console.log(locationJson[this.props.location.pathname.substring(1,3)])
    // console.log(this.props)


    if (!this.getCookie('gclid') || this.getCookie('gclid') === 'undefined') {
      const gclidVal = queryString.parse(this.props.location.search).gclid;
      this.setCook('gclid', gclidVal, 30);
    }
    if (!this.getCookie('ad') || this.getCookie('ad') === 'undefined') {
      const adVal = queryString.parse(this.props.location.search).ad;
      this.setCook('ad', adVal, 30);
    }
    if (!this.getCookie('ag') || this.getCookie('ag') === 'undefined') {
      const agVal = queryString.parse(this.props.location.search).ag;
      this.setCook('ag', agVal, 30);
    }
    if (!this.getCookie('c') || this.getCookie('c') === 'undefined') {
      const cVal = queryString.parse(this.props.location.search).c;
      this.setCook('c', cVal, 30);
    }
    if (!this.getCookie('k') || this.getCookie('k') === 'undefined') {
      const kVal = queryString.parse(this.props.location.search).k;
      this.setCook('k', kVal, 30);
    }
    if (!this.getCookie('m') || this.getCookie('m') === 'undefined') {
      let mVal = queryString.parse(this.props.location.search).m;
      if (mVal === undefined) {
        if (queryString.parse(this.props.location.search).msclkid) {
          mVal = "bing";
        }
      }
      this.setCook('m', mVal, 30);
    }
    if (!this.getCookie('lpurl') || this.getCookie('lpurl') === 'undefined') {
      const lpurl = (window.location.href);
      this.setCook('lpurl', lpurl, 30);
    }

    console.log(this.props, "this is me!")

    this.setState({
      renderedButtons: this.buttonRender(this.state.node.buttons),
      renderedForm: this.formRender(this.state.formFields),
      sessionId: v4(),
      formData: {
        ...this.state.node.formData,
        cookies: {
          name: queryString.parse(this.props.location.search).name,
          locationUuid: locationJson[this.props.location.pathname.substring(1,3)] && locationJson[this.props.location.pathname.substring(1,3)],
          pageTitle: this.props.location.pathname,
          gclid: this.getCookie('gclid'),
          ad: this.getCookie('ad'), // ad creative
          ag: this.getCookie('ag'), // ad group
          c: this.getCookie('c'),
          k: this.getCookie('k'),
          m: this.getCookie('m'), // match type
          // patient: this.props.cookies.cookies.patient,
          locationId: queryString.parse(this.props.location.search).LUUID
        },
      },
    });
    // console.log(this.state)
    // takes you to the previous node if back button is hit
    window.addEventListener('popstate', e => {
      e.preventDefault();
      this.getPreviousNode();
    });
  }

  addToButtonsInContent = newButtons => {
    this.setState({
      buttonsInContent: 'Something',
    });
  };

  setCook = (name, value, days) => {
    console.log("setting " + name)
    const date = moment().add(days, 'days').toDate();
    const expires = `; expires=${date.toGMTString()}`;
    // const date = new Date();
    // date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    // const expires = `; expires=${date.toGMTString()}`;
    document.cookie = `${name}=${value}${expires};path=/`;
  };
  getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
    return 'undefined';
  };
  toggle = () => {
    // console.log(1);
    this.setState({
      modal: !this.state.modal,
    });
  };

  getNextNode(selectedButton) {
    console.log(this.state.formData, "getNextNode formadata")
    const bodyJson = {
      patientId: this.state.patientId,
      formData: this.state.formData,
      session: this.state.sessionId,
      tree: this.state.node.treeId,
      node: this.state.node.node,
      button: selectedButton,
      link: this.state.node.link,
    };

    const listofFieldKeys = Object.keys(this.state.formFields);
    const listOfFields = listofFieldKeys.map(eachKey => this.state.formFields[eachKey]);
    // console.log(listOfFields);
    const requiredFields = listOfFields.filter(eachField => eachField.required == '1');
    // console.log(requiredFields);
    const requiredCheck = requiredFields.reduce((total, eachField) => {
      // console.log('this is a required field', eachField.name);
      if (this.state.formData[eachField.name] == null) {
        // console.log(this.state.formData);
        // console.log(eachField.name)
        // console.log(total)
        return 1 + total;
      }
      return total;
    }, 0);

    if (requiredCheck === 0 && this.state.waitingOnBackEndResponse === false) {
      // console.log("required check indicator:", requiredCheck)
      // this.setState({ waitingOnBackEndResponse: true });
      console.log("bodyJson", bodyJson)
      fetch('/api/getNextNode/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(rsp => {
          // console.log(1)
          console.log('fetch api response', rsp);
          // console.log(this.state)
          if (rsp.link !== null) {
            this.props.history.push({
              pathname: rsp.link,
              state: { url:this.props.location.pathname }
            });
          }
          this.setState(
            {
              formData: {},
              formFields: rsp.formFields,
              waitingOnBackEndResponse: false,
            },
            () => {
              // console.log('this is formdata during submission', this.state.formData);
              this.setState({
                node: rsp,
                renderedButtons: this.buttonRender(rsp.buttons),
                renderedForm: this.formRender(),
                nodesToGoBack: 1,
              });
              window.scroll(0, 0);
            },
          );
        });
      // console.log(2)
      let tempPath =this.props.location.pathname; 
        // console.log(window.location.href)
      this.props.history.push({
        // pathname: `/${this.props.location.pathname}`,
        // pathname: `/${this.state.startingTree}`,
        pathname: `${tempPath}`,
        // node: 'testing',
      });
      // console.log(3)

    } else {
      // console.log(requiredCheck)
      this.toggle();
    }
  }

  getPreviousNode() {
    const bodyJson = {
      nodesToGoBack: this.state.nodesToGoBack,
      session: this.state.sessionId,
    };

    fetch('/api/getPreviousNode/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyJson),
    })
      .then(response => response.json())
      .then(rsp => {
        // console.log('fetch api response', rsp);
        this.setState(
          {
            formData: {},
            formFields: rsp.formFields,
          },
          () => {
            // console.log('this is formdata during submission', this.state.formData);
            this.setState({
              node: rsp,
              renderedButtons: this.buttonRender(rsp.buttons),
              renderedForm: this.formRender(),
              nodesToGoBack: Number(this.state.nodesToGoBack) + Number(1),
            });
          },
        );
      });
  }

  buttonRender(buttonData) {
    /*
    This is what button data looks like
    0: {
      button_data: "0",
      button_link: "12",
      button_text: "There&#39;s nothing wrong. I just need an oil change or some other maintenance.",
      project_node_id: "1",
      value: "0",
    },
    1: { ....}
    */
    const listOfButtonNumbers = Object.keys(buttonData);

    const theButtons = listOfButtonNumbers.map((eachButtonNumber, iii) => {
      const reactButtonContent = JSON.stringify(
        buttonData[eachButtonNumber].button_text
      ).replace(/"/g, "");
      // console.log("reactButtonContent", reactButtonContent);
      // const reactButtonContent = this.htmlToReactParser.parse(
      //   buttonData[eachButtonNumber].button_text,
      // );
      return (
        <div className="w-20 tree-btn-container" key={iii}>
          <button className="tree-btn" key={iii} onClick={() => this.getNextNode(eachButtonNumber)}>
            {reactButtonContent}
          </button>
          
        </div>

        // <button onClick={() => this.getNextNode(eachButtonNumber)}>{reactButtonContent}</button>
      );
    });
    return theButtons;
  }

  onFormInputChange(e) {
    // console.log('e.target', e.target.files)
    // console.log('e.target.value', e.target.value);
    // console.log('e.target.id', e.target.id);
    // console.log('e.target.name', e.target.name);
    if (e.target.type === 'checkbox') {
      console.log(e.target)
      if (e.target.checked) {
        this.setState({ formData: { ...this.state.formData, [e.target.id]: true } });
      } else {
        this.setState({ formData: { ...this.state.formData, [e.target.id]: false } });
      }
    } else if (e.target.type === 'radio') {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      });
    } else if (e.target.type === 'file') {
      const uploadAsyncImage = async () => {
        const fileData = new FormData();
        console.log(e.target)
        // fileData.append('file', e.target.files[0])
        fileData.append('title', e.target.id)
        const uniqueFile = e.target.files[0];
        this.setState({
          formData: { ...this.state.formData, [e.target.id]: uniqueFile, test: "this is how" }
        })
        const options = {
          maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
          // maxWidthOrHeight: number,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
          // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100) 
          // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)

          // following options are for advanced users
          // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
          // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
          // fileType: string,           // optional, fileType override
          // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
        }
        const compressed = imageCompression(uniqueFile, options);
        console.log(await compressed)
        fileData.append('file', await compressed)

        fetch('/api/uploadImage/', {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' },
          body: (await fileData),
        })
          .then(response => response.json())
          .then(rsp => {
            // console.log(rsp)
          })
      }
      uploadAsyncImage()

    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.id]: e.target.value },
      });
      // console.log(this.state, e.target.value);

    }
  }

  formRender() {
    const formFieldList = this.state.formFields;
    // console.log('formFieldList', formFieldList);
    const listOfFormFieldsKeys = Object.keys(this.state.formFields);
    // console.log('listOfFormFieldsKeys', listOfFormFieldsKeys);
    if (listOfFormFieldsKeys.length > 0) {
      return listOfFormFieldsKeys.map((eachFormKey) => formDifferentiation(eachFormKey, formFieldList, this));
    }
  }

  render() {
  
    // console.log(this.state.node);
    const splitContent = this.state.node.content.split('##data-entry-fields##');
    let reactNodeContent2 = null;
    const reactNodeContent1 = splitContent[0];

    if (splitContent.length > 1) {
      reactNodeContent2 = JSON.stringify(
        splitContent[1],
      );
    }
    const question = (
      <div className="nodeQuestion">{JSON.stringify(this.state.node.question)}</div>
    );
    return (
      <CookiesProvider>
        {/* <AdCookie location={this.props.location}/> */}
        <div id="newrequesttrees">
          <Mask className="d-flex justify-content-center responsive-align text-center white">
            <Container fluid className=""> 
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className="text-center"
                centered
              >
                <ModalHeader toggle={this.toggle}>Info Required</ModalHeader>
                <ModalBody>
                  <strong> You must fill out the required fields to proceed. </strong> <br /> <br />
                    {/* Not working for you? Call one of our centers or talk to us in location and we can help the old fashion way - with a human */}
                    :) <br />
                  <br />
                  <br />
                </ModalBody>
                <Button color="danger" onClick={this.toggle}>
                  Close
                  </Button>
              </Modal>
              <Modal
                isOpen={this.state.waitingOnBackEndResponse}
                className="text-center"
                centered
              >
                <ModalBody>
                  <span style={{ fontWeight: '900' }}> ... Calculating </span> <br />
                    If this takes more than a few seconds, there might be a problem on our end.
                    Sorry about this. Maybe our servers are overheating. Maybe our engineers didn't drink
                    enough coffee. If you want, you can one of our centers or talk to us in location and we can help the old fashion way - with a human :) <br />
                  <br />
                  <br />
                  <Button
                    color="danger"
                    onClick={() => {
                      this.setState({ waitingOnBackEndResponse: false });
                    }}
                  >
                    Close
                    </Button>
                </ModalBody>
              </Modal>
              <Node reactNodeContent1={reactNodeContent1} reactNodeContent2={reactNodeContent2} question={this.state.node.question} buttons={this.state.renderedButtons} form={this.state.renderedForm} />
              {/* <Row className="align-items-center mt-5">
                  <Col className="mx-0 px-0 mb-3">
                    <div id="treeContent" />
                    <div dangerouslySetInnerHTML={{ __html: reactNodeContent1 }} />
                    {this.state.renderedForm}
                    <div dangerouslySetInnerHTML={{ __html: reactNodeContent2 }} />
                  </Col>
                  <div className="well w-100">
                    {this.state.node.question ? question : null}
                    <Row>
                      <div className="w-100">{this.state.renderedButtons}</div>
                    </Row>
                  </div>
                  <Row>
                    <Col size="12">
                      <Button
                        className="w-100 m-1 back-btn black-text"
                        onClick={() => this.getPreviousNode()}
                      >
                        <Fa icon="arrow-left" className="black-text" />{' '}
                        <span className="black-text">Back</span>
                      </Button>
                    </Col>
                  </Row>
                  {parsedTestHTML}
                </Row> */}
              {/* </Col> */}
            </Container>
          </Mask>
        </div>
      </CookiesProvider>
    );
  }
}

export default withRouter(withCookies(TreeRenderer));

/*
This is what processing instructions shows:
Element {type: "tag", parent: Element, prev: null, next: null, startIndex: null, …}
attribs:
href: "#3"
onclick: "do_button_click(7, 10, 'next',0);"
__proto__: Object
childNodes: (...)
children: Array(1)
0: Element
attribs: {src: "media/images_1526587775705-1526587775705.jpg", style: "width:100%;", class: "fr-fic fr-dii fr-fil"}
childNodes: (...)
children: []
endIndex: null
firstChild: (...)
lastChild: (...)
name: "img"
next: null
nextSibling: (...)
nodeType: (...)
parent: Element {type: "tag", parent: Element, prev: null, next: null, startIndex: null, …}
parentNode: (...)
prev: null
previousSibling: (...)
startIndex: null
tagName: (...)
type: "tag"
__proto__: NodeWithChildren
length: 1
__proto__: Array(0)
endIndex: null
firstChild: (...)
lastChild: (...)
name: "a"
next: null
nextSibling: (...)
nodeType: (...)
parent: Element {type: "tag", parent: Element, prev: null, next: null, startIndex: null, …}
parentNode: (...)
prev: null
previousSibling: (...)
startIndex: null
tagName: (...)
type: "tag"
__proto__: NodeWithChildren
TreeRenderer.js:494 this is node from processingInstructions
Element {type: "tag", parent: Element, prev: null, next: null, startIndex: null, …}
attribs: {src: "media/images_1526587775705-1526587775705.jpg", style: "width:100%;", class: "fr-fic fr-dii fr-fil"}
childNodes: (...)
children: Array(0)
length: 0
__proto__: Array(0)
endIndex: null
firstChild: (...)
lastChild: (...)
name: "img"
next: null
nextSibling: (...)
nodeType: (...)
parent: Element {type: "tag", parent: Element, prev: null, next: null, startIndex: null, …}
parentNode: (...)
prev: null
previousSibling: (...)
startIndex: null
tagName: (...)
type: "tag"
__proto__: NodeWithChildren

*/
