import * as React from 'react';
import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/Main';

export default class App extends Component {
  render () {
    // if (!(
    //   !process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
    //   !window.location.host.startsWith("www")) {
    //     window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;
    //     }
    return (
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    );
  }
}
