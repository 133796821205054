const state = {
  node: {
    type: 'Content',
    treeId: 5,
    node: 2,
    is_root: '0',
    node_name: '',
    page_title: 'Intro',
    content:
    `<div>
    <h2 style="text-align: center;"> Thank you for visiting <br/> Swift Star Emergency Room</h2> 
    <h4> We hope you are feeling better!</h4>
    <br />
    <br />
    <p>To help us improve, can you quick 5-question survey? </p>
    <p>Please let us know how much you agree or disagree with the following statements.</p>
    <div/>`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: "",
        rank: "1",
        value: "0",
        updated: "0",
        hover_text: "",
        button_data: "0",
        button_link: "1",
        button_text: "Let's begin!",
        wizard_skip: "0",
        project_node_id: "2",
        logic_expression: ""
      },
    },
  },
  formData: {},
  formFields: {
    // '0': {
    //   name: 'failedpart',
    //   type: 'multiline',
    //   label: 'Tell us what part failed as best as you can',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '1',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    // '1': {
    //   name: 'HowInfographic',
    //   type: 'hidden',
    //   label: 'Howinfographic:',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '0',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: 'C',
    //   checkbox_score: '1',
    // },
    // '0': {
    //   name: 'email',
    //   type: 'text',
    //   label: 'Welcome to Emergency Center online checkin.',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '0',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    // '2': {
    //   name: 'phone',
    //   type: 'phone',
    //   label: 'enter your phone number',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '1',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    // '5': {
    //   name: 'Engine',
    //   rank: '0',
    //   type: 'radio',
    //   label: 'What engine do you have?',
    //   inline: '0',
    //   scores: '',
    //   options: '4 Cylinder\r\n6 Cylinder\r\n8 Cylinder\r\nDiesel\r\nHybrid\r\nI&apos;m not sure',
    //   required: '0',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hidden_value: '',
    //   checkbox_score: '1',
    // },
    // '6'
    // '8': {
    // '6': {
    //   name: 'TireRotation',
    //   type: 'checkbox',
    //   label: 'Tire rotation',
    // },
    // "8": {
    //                 "type": "multiline_full",
    //                 "name": "otherInfo",
    //                 "label": "Write us a note here (optional)",
    //                 "label_type": "P",
    //                 "options": "",
    //                 "hidden_value": "",
    //                 "required": "0",
    //                 "score_var": "",
    //                 "scores": "",
    //                 "rank": "0",
    //                 "checkbox_score": "1",
    //                 "custom_regex": "",
    //                 "inline": "0"
    //             }
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
