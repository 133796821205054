import { useEffect, useState } from 'react';
import { Button, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import FindAddress from './findAddress';

const containerStyle = {
  maxWidth: "500px",
  maxHeight: '350px',
  height: 'calc(75vw - 1rem)',
  // width: 'calc(100vw - 1rem)',
  margin: 'auto',
  position: 'relative'

};

const FindNearest = props => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDw0uxUc4cxPpSIJsljsHsMYMf5ewBh2rg"
  })
  const [nearest, setNearest] = useState(<></>);
  const [modal, setModal] = useState(false);

  useEffect(() => {

  }, [])

  function toggle() {
    setModal(!modal);
  }
  function distance(lat1, lon1, lat2, lon2, locName) {
    const dLon = lon2 - lon1;
    const dLat = lat2 - lat1;
    const a =
            Math.sin(2 * 3.14 / 360 * dLat / 2) * Math.sin(2 * 3.14 / 360 * dLat / 2) +
            Math.cos(2 * 3.14 / 360 * (lat1)) * Math.cos(2 * 3.14 / 360 * (lat2)) *
            Math.sin(2 * 3.14 / 360 * dLon / 2) * Math.sin(2 * 3.14 / 360 * dLon / 2)
            ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = 3963 * c; // Distance in mi
    return d;
  }
  async function sendLocData(locationData, distance, accuracy, latlong, extraStuff) {
    const getCookie = name => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts
          .pop()
          .split(';')
          .shift();
      }
      return 'undefined';
    };

    const body = {
      latitude: parseFloat(latlong.latitude),
      longitude: parseFloat(latlong.longitude),
      distance: distance,
      didClickGD: false,
      gclid: getCookie('gclid'),
      locationUuid: locationData.uuid,
      lpUrl: getCookie('lpurl'),
      accuracy: { accuracy },
      ad: getCookie('ad'),
      adGroup: getCookie('ag'),
      campaign: getCookie('c'),
      keyword: getCookie('k'),
      matchType: getCookie('m'),
      locationName: locationData.area,
      covid: false,
      extraStuff
    }
    fetch('/api/loc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(body),
    }).then(response => {
      if (response.ok) {
      } else {
        console.log('Error posting data!');
        console.log('form response:', response);
      }
    });
  }
  function googlemap(center) {
    return isLoaded ? (
      <div style={{ height: "25%", width: "100%" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          initialCenter={center}
          zoom={15}
        >
          <></>
          <Marker
            position={center}
            title={"Village Emergency Center"}

          />
        </GoogleMap>
      </div>
    ): <></>
  }
  function formatNearest(locationData) {
    return (
      <div className="mb-md-0 mb-0" style={{ margin: "auto" }}>
        <MDBModalHeader toggle={() => { setModal(false) }} >
          <b>Village Emergency Center @ {locationData.area}</b>
        </MDBModalHeader>
        <MDBModalBody>
          <img src={require('../../images/' + locationData.picture)} className="findMeImage" />
          <br />
          <br />
          <div className="locationAddress">
            <a href={`tel:${locationData.phone}`} onClick={() => {
              window.dataLayer.push({ event: 'IWPC' });
              window.dataLayer.push({ event: `${locationData.gtagid}IWPC` });
            }}>{locationData.phone}</a>
            <br />
            {locationData.address.split('|').map((eachRow, index) => {
              return (
                <span key={"locationaddress" + index + 1}>
                  {eachRow}
                  <br />
                </span>
              );
            })}
          </div>
          <a
            href={locationData.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              window.dataLayer.push({ event: 'GD' });
              window.dataLayer.push({ event: 'GD2' });
              window.dataLayer.push({ event: `${locationData.gtagid}GD2` });
              // window.uetq = window.uetq || [];
              // window.uetq.push('event', 'gd_er', {});
            }
            }
          >
            <Button color="danger" className="d-block mx-auto px-3" style={{ marginBottom: "1rem" }}>
              <span className="locationButton">GET DIRECTIONS</span>
            </Button>
          </a>
          {googlemap({ lat: parseFloat(locationData.lat), lng: parseFloat(locationData.long) }, locationData)}
        </MDBModalBody>
      </div>)
  }
  async function findNearest(position, extraStuff) {
    const closestLocation = props.locationSectionJson.reduce((closest, eachLocation) => {
      if (distance(eachLocation.lat, eachLocation.long, position.coords.latitude, position.coords.longitude, eachLocation.area) < distance(closest.lat, closest.long, position.coords.latitude, position.coords.longitude, closest.area)) {
        return eachLocation
      } else {
        return closest
      }
    })
    sendLocData(closestLocation, distance(closestLocation.lat, closestLocation.long, position.coords.latitude, position.coords.longitude), position.coords.accuracy, position.coords, extraStuff)
    setNearest(formatNearest(closestLocation))
    return formatNearest(closestLocation);
  }

  return (
    <>
      <div className="col-12 locationButton" id="locationanchor"><h5>
        {("geolocation" in navigator) && <button className="redGDButton d-block mx-auto px-3 py-2"
          onClick={async () => {
            window.dataLayer.push({ event: 'NLC' });
            if ("geolocation" in navigator) {
              toggle();
              navigator.geolocation.getCurrentPosition(findNearest,
                (err) => {
                  console.log(err);
                  setNearest(
                    <div>
                      <div style={{ margin: "3rem", marginBottom: "2rem", textAlign: "left" }}>
                        <h5 style={{ fontWeight: "900" }}>Locator:</h5>
                        <span style={{ fontSize: ".95rem" }}>
                                      It looks like your device won&apos;t tell us your location.
                                      To get the nearest location, enter your address:
                        </span>
                      </div>
                      <FindAddress setNearest={setNearest} findNearest={findNearest} />
                    </div>
                  )
                },
                setNearest(
                  <div style={{ fontWeight: "300", marginTop: "1rem" }}> Calculating nearest location, this may take a couple seconds...
                    <br />
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                ),
                err => {
                  console.log(err);
                  setNearest(<div>This browser does not support geolocation.</div>)
                })
            } else {
              console.log("Error obtaining geolocation")
            }
          }}>
          <span className="locationButton">Find Nearest Location</span>
        </button>}
      </h5>
      </div>
      <div className="col-12"><h5> <div className="col-6 col-md-4 pt-2 pt-sm-2 pt-md-2 mx-auto black-text" key={"closestlocationinfo"} >
        <MDBModal isOpen={modal} toggle={toggle}>
          {nearest && nearest}
        </MDBModal>
      </div></h5>
      </div>

    </>
  )
}

export default FindNearest;
