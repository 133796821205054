import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarNav,
  NavItem,
  NavLink,
  // NavbarToggler,
  Collapse, MDBModal,
  // MDBModalBody,
  MDBModalHeader, MDBModalFooter, MDBBtn
} from 'mdbreact';
import './Navbar.css';
// import AsyncComponent from './Loading/AsyncComponent';
// const FindNearest = AsyncComponent(() => import('./FindNearestNav'));
import FindNearest from './FindNearestNav';

function NavBar (props) {
  const [collapse, setCollapse] = useState(false);
  const [togglePopup, settogglePopup] = useState(false);
  const onClick = () => {
    setCollapse(!collapse);
  };

  const onClickSpecial = () => {
    setCollapse(false);
  };

  const handlePopup = () => {
    settogglePopup(!togglePopup);
  };

  const handleNavbarClick = () => {
    setCollapse(false);
  };

  const overlay = (
    <div
      id="sidenav-overlay"
      style={{ backgroundColor: 'transparent' }}
      onClick={handleNavbarClick}
    />
  );

  return (
    <div id="navbar">
      <div style={{ height: "50px" }}>
        <Navbar
          dark
          // expand="md"
          fixed="top"
          //   scrolling
          className={props.navVariant ? props.navVariant : ''}
          style={{ paddingRight: '0px' }}
        >
          {/* <Container> */}
          <span className="routable-navbar-brand">
            <Link to="/">
              <div style={{ }}>
                <img
                  alt="logo"
                  src={require(`../../images/${props.local.logoFile}`)}
                  className="navLogo"
                  // style={{ height: '65px' }}
                  // width="125px"
                  // height="36px"
                  // style={{ maxWidth: '30vw' }}
                />
              </div>
            </Link>
          </span>
          <span className="nav-item text-center" id="locationanchor" style={{ fontSize: ".85rem", marginLeft:"auto" }}>
          <span className="redButton nav-link-button ripple-parent"><span className="" style={{ fontWeight: "500" }}>Call Us</span></span>
          </span>
{/*           
          <NavbarNav className="my-auto" right >
            <FindNearest locationSectionJson={props.local.locations}/>
          </NavbarNav> */}
          {/* <NavbarToggler onClick={onClick} aria-label="Navigation Menu Toggle" /> */}
          <button
            onClick={onClick}
            className="navbar-toggler collapsed"
            data-toggle="collapse" aria-label="navigation toggle">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
          {/* <Router> */}
          <Collapse isOpen={collapse} navbar>
            <NavbarNav className="my-auto" right>
              <NavItem onClick={onClick}>
                <NavLink className="nav-link" to="/">
                  <b>Home</b>
                </NavLink>
              </NavItem>
              {/* <NavItem onClick={onClick}>
                <a className="nav-link" href="https://www.swiftstar.com/emergency-room-services/">
                  <b>Emergency Services</b>
                </a>
              </NavItem> */}
              {/* <NavItem onClick={onClick}>
                <NavLink className="nav-link" to="/insurance">
                  <b>Insurance Info & Billing</b>
                </NavLink>
              </NavItem> */}
              <NavItem onClick={onClick}>
                <NavLink className="nav-link" to="https://www.swiftstar.com/about-us">
                  <b>About</b>
                </NavLink>
              </NavItem>
              {/* <NavItem onClick={onClick}>
                <NavLink className="nav-link" to="/events">
                  <b>Events</b>
                </NavLink>
              </NavItem> */}
              {/* <NavItem onClick={onClick}>
                <NavLink className="nav-link" to="/careers">
                  <b>Careers</b>
                </NavLink>
              </NavItem> */}
              {/* <li onClick={onClick} className="nav-item"> */}
              {/* <Link className="nav-link" href="https://blog.austiner.com/">
                  <b>Blog</b>
                </Link> */}
              {/* </li> */}
              <MDBModal isOpen={togglePopup} toggle={handlePopup} size="lg">
                <MDBModalHeader toggle={handlePopup} style={{ backgroundColor: " #d22626" }}>
                  <img
                    alt="Village Emergency Center logo"
                    src={require(`../../images/${props.local.logoFile}`).default}
                    className="img-fluid"
                    style={{ maxWidth: "9rem", marginLeft: "2rem" }}
                  />
                </MDBModalHeader>
                {/* <MDBModalBody >
                  <iframe src="/MailChimpSignup.html" style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center"
                  }}></iframe>
                </MDBModalBody> */}
                <MDBModalFooter>
                  <MDBBtn color="danger" onClick={handlePopup}>Close</MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </NavbarNav>

          </Collapse>
          {/* </Router> */}
          {/* </Container> */}
        </Navbar>
        {collapse && overlay}
      </div>
    </div>
  );
}

export default NavBar;
