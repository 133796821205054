import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as variableLocalContent from './variablePath.json';
import lpVariants from '../components/Homepage/lpVariants.json';
import TreeRenderer from '../components/Exit/TreeRenderer';
import Landingpage from '../components/Homepage/LandingPages';
import DesktopLandingPage from '../components/Homepage/DesktopLandingPage';
import CarouselLandingPage from '../components/Homepage/CarouselLandingPage';
// import Temp from '../components/Temp.js';
// const Feedback = lazy(() => import('../Feedback/Feedback'));
// const RSV = lazy(() => import(`../monkeypox/rsv`));
// const Flu = lazy(() => import(`../Flu/Flu`));
import Covid from '../components/Covid/Covid';
import CovidAlt from '../components/Covid/CovidAlt';
import AboutUs from '../components/About/AboutUs';
import Services from '../components/Services/Services';
import Insurance from '../components/Billing/Insurance';
import ThankYou from '../components/Exit/ThankYou';
import VisitComplete from '../components/Exit/VisitComplete';

// import * as eventData from '../Events/Events.json';
const renderLoader = () => <p>Loading</p>;
let variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];

export default (
  <Switch>
    {/* {lpVariants.lpVariants.map((eachPage, index) => {
      return <Route
        key={"index" + index}
        path={"/old/" + eachPage.path}
        exact
        render={props => (
          <Landingpage
            variant={eachPage.name}
            location={props.location}
            history={props.history}
            context={variableContent}
          />
        )}
      />
    })}
    {lpVariants.lpVariants.map((eachPage, index) => {
      return <Route
        path={"/" + eachPage.path}
        key={"index" + index + "-vid"}
        exact
        render={props => (
          <DesktopLandingPage
            variant={eachPage.name}
            location={props.location}
            history={props.history}
            context={variableContent}
          />
        )}
      />
    })}
    {lpVariants.lpVariants.map((eachPage, index) => {
      return <Route
        path={"/car/" + eachPage.path}
        key={"index" + index + "-car"}
        exact
        render={props => (
          <CarouselLandingPage
            variant={eachPage.name}
            location={props.location}
            history={props.history}
            context={variableContent}
          />
        )}
      />
    })}
    <Route
      path="/covid"
      exact
      render={props => (
        <Covid
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/covidalt"
      exact
      render={props => (
        <CovidAlt
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/about"
      exact
      render={props => (
        <AboutUs
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    />
    <Route
      path="/services"
      exact
      render={props => (
        <Services
          location={props.location}
          history={props.history}
          context={variableContent}
        />
      )}
    /> */}
    {/* <Route path="/hhdischargesurvey" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"veccheckin"}
      />
    )} /> */}
    <Route path="/rodischargesurvey" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"veccheckin"}
      />
    )} />
    <Route path="/kadischargesurvey" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"veccheckin"}
      />
    )} />
    {/* <Route path="/ccdischargesurvey" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"veccheckin"}
      />
    )} />
    <Route path="/jvdischargesurvey" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"veccheckin"}
      />
    )} /> */}
    {/* <Route path="/employeeincentive" exact render={props => (
      <TreeRenderer
        location={props.location}
        history={props.history}
        context={variableContent}
        tree={"vecemployeevideo"}
      />
    )} /> */}
    <Route path="/visitcomplete" exact render={props => (
      <VisitComplete
        location={props.location}
        history={props.history}
        context={variableContent}
      />
    )} />
    <Route path="/thankyou" exact render={props => (
      <ThankYou
        location={props.location}
        history={props.history}
        context={variableContent}
      />
    )} />
    {/* <Route path="/insurance" exact render={props => (
      <Insurance
        location={props.location}
        history={props.history}
        context={variableContent}
      />
    )} /> */}
  </Switch>
);
