import * as React from 'react';
import { Col, Container, Row } from 'mdbreact';
import { Link } from 'react-router-dom';
import './Footer.css';
import moment from 'moment';

// Footer locations section is hard coded and will need to be manually updated with more locations, or restructured. Uses a
// differrent format for styling the column width and spacing than other locations.

const FooterPage = props => {
  return (
    <section className="font-small pt-4 mt-0 footerStyles white-text" id="footer">
      <Container fluid className="text-center text-md-center">
        <Row>
          <Col md="3">
          
            <h5 className="title" style={{ textTransform: "uppercase", fontWeight: "700" }}>Sitemap</h5>
            <p style={{ fontWeight: "500" }}>
              {/* <br />
              <Link to="/team">Our Team</Link> */}
              <br />
              <a href="https://swiftstar.com/">Home</a>
              <br />
              <a href="https://www.swiftstar.com/insurance-info"> Insurance Info & Billing</a>
              <br />
              <a href="https://www.swiftstar.com/about-us">About Us / More Info</a>
              {/* <br />
              <Link to="/events">Events</Link>
              <br />
              <Link to="/careers">Careers</Link> */}
              <br />
              <a href="https://www.swiftstar.com/privacy-policy" target = "_blank" rel="noreferrer">Privacy Policy</a>
              <br />
              <a href="https://www.swiftstar.com/billing-disclosure" target = "_blank" rel="noreferrer">Billing Disclosure</a>
              <br />
              <a href="https://www.swiftstar.com/disclaimer-statement" target = "_blank" rel="noreferrer">Disclaimer Statement</a>
              <br />
              <a href="https://www.swiftstar.com/patient-rights-and-responsibilities" target = "_blank" rel="noreferrer">Patient Rights and Responsibilities</a>
              <br />
              <a href="https://www.swiftstar.com/accessibility-compliance-statement" target = "_blank" rel="noreferrer">Accessibility Compliance Statement</a>
              <br />
              <a href="https://www.swiftstar.com/_files/ugd/491b63_7cfdded3243c4f50a80ea7cf19bac837.xlsx?dn=ChargeMaster%20Facilities%20Fees.xlsx" target = "_blank" rel="noreferrer">Charge Master</a>
              <br />

            </p>
          </Col>
          <Col md="3">
            {/* <h5 className="title" style={{ textTransform: "uppercase", fontWeight: "700" }}>Follow Us</h5>
            <p>
              <a href="https://www.facebook.com/RiverOaksEmergencyRoom/" aria-label="facebook" style={{ margin: "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(169deg) brightness(105%) contrast(106%)" }} width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
              </a>
              <a href="https://www.linkedin.com/company/village-emergency-centers/" aria-label="linkedin">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(169deg) brightness(105%) contrast(106%)" }} width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
              </a>
            </p> */}
          </Col>
          <Col md="6">
            <h5 className="title" style={{ textTransform: "uppercase", fontWeight: "700" }}>Our Locations</h5>
            <div style={{ columns: '2' }}>
              {props.local.locations.map((eachLocation, index) => {
                return (<p className="my-auto mx-auto" key={"footerLocation" + index} style={{ breakInside: "avoid-column", display: "table", fontWeight: "500" }}>
                  <span style={{ textTransform: "uppercase", fontWeight: "700" }}><b>{eachLocation.area}</b></span>
                  <br />
                  <><a href={`tel:${eachLocation.phone}`}>{eachLocation.phone}</a><br /></>

                  {eachLocation.address.split('|').map((eachRow, ind) => {
                    return (
                      <span key={"footerAddress" + ind}>
                        {eachRow}
                        <br />
                      </span>
                    );
                  })}
                  <br />
                </p>)
              })
              }
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-copyright text-center pb-3">
        <Container fluid>
          &copy; Copyright © {moment().year()} Swift Star Emergency Rooms. All
          Rights Reserved. <br />
        </Container>
      </div>
    </section>
  );
};

export default FooterPage;
