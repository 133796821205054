import { useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  MDBContainer,
  MDBView
} from 'mdbreact';
import { NavHashLink } from 'react-router-hash-link';
import '../../Homepage/Intro.css';
import tombstonePhoto from '../../../images/billing.jpeg';
// Text in this component is a fallback in case the lpVariant prop is null (see lpVariants.json to change homepage text)
const Intro = props => {
  const introText = (
    <div className="white-text mt-5" >
      <br />
      <br />
      <div className="d-flex justify-content-center">
        <h1 className="font-weight-bold h4-responsive" style={{ textTransform: 'uppercase', textAlign: "center"  }}>
          <span style={{ fontSize: "1.5em"}} key={"title"}>
            Billing & Payments
            <br />
            <hr style={{ borderTop: '3px solid white', marginLeft: '1rem', marginRight: '1rem' }} className="hr-light mb-0" />
          </span>
        </h1>
      </div>
      <br />
      <Row className="d-flex justify-content-center">
        <div className="white-text text-center mt-0 pt-0 mb-0 justify-content-center">
          <h3 className="h3-responsive">
            <div className="font-weight-bold rt">
              <span key={"introhead"}>
                      Easy & Transparent
                <br />
              </span>
            </div>
          </h3>
        </div>
      </Row>
      <div className="white-text text-center mt-3 pt-0 mb-5 justify-content-center">
        <Row>
          <Col md="3" />
          <Col md="6 mx-auto">
            <h4 className="h5-responsive">
              <div className="rt">
                No Wait Emergency Room
              </div>
            </h4>
            <h6 className="mb-4 mx-auto">
              <div className="rt text-center">
                <div>Board Certified Doctors</div>
                <div>On-Site Diagnostics and Labs</div>
                <div>Open 24/7/365</div>
              </div>

            </h6><a href="https://pay.balancecollect.com/m/riveroaksemergencyphysicianstaff">
              <Button color="danger" className="d-block mx-auto px-3" onClick={() => {
              }
              }>
                <span style={{ fontWeight: 'bolder' }}> Pay Bill </span>
              </Button></a>
          </Col>
          <Col md="3" />
        </Row>
      </div>
    </div>
  );
  return (
    <div id="" style={{ backgroundColor: "#353c57",
    //  backgroundImage:`url(${tombstonePhoto})`, backgroundPosition:"center" 
     }}>
      <MDBView className="d-flex justify-content-center align-items-center">
        <MDBContainer fluid className="m-0 p-0">
          {introText}
        </MDBContainer>
      </MDBView>
    </div>
  );
};
export default Intro;
