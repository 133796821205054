import * as React from 'react';
import { Col, Row, Input } from 'mdbreact';
import './Trees.css';

export default function formDifferentiation(eachFormKey, formFieldList, parentThis) {
  // console.log(eachFormKey);
  if (formFieldList[eachFormKey].type === 'multiline' ||
    formFieldList[eachFormKey].type === 'multiline_full') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {console.log(formFieldList[eachFormKey].name)} */}
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="on"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'number') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {console.log(formFieldList[eachFormKey].name)} */}
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              type="number"
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="on"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'address') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="street-address"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'select') {
    const fieldOptions = formFieldList[eachFormKey].options.split('\n');
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {formFieldList[eachFormKey].label && <p>{formFieldList[eachFormKey].label}</p>} */}
            <select
              id={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[formFieldList[eachFormKey].name]}
              className="browser-default custom-select"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
            >
              {fieldOptions.map(fieldOption => (
                <option value={fieldOption}>{fieldOption}</option>
              ))}
            </select>
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'text') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block', textAlign: "left" }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'date') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              type="date"
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
              autofill="bday"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'email') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
              key={formFieldList[eachFormKey].name}
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'phone') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              type="number"
              id={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={parentThis.state.formData[parentThis.state.formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'radio') {
    const fieldOptions = formFieldList[eachFormKey].options.split('\n');
    return (
      <div className="formArea" key={eachFormKey}>

        {/* <ul className="likert mx-auto"> */}
        <ul className="" style={{ listStyleType: "none", padding: "0px", textAlign: "left", marginLeft: "15px" }}>
          <label
            htmlFor={formFieldList[eachFormKey].name}
            id={formFieldList[eachFormKey].name}
            className="qlabel"
          >
            {formFieldList[eachFormKey].label}
          </label>
          {fieldOptions.map(fieldOption => (
            <li key={fieldOption + formFieldList[eachFormKey].name}>
              <input
                type="radio"
                name={formFieldList[eachFormKey].name}
                value={fieldOption}
                onChange={e => parentThis.onFormInputChange(e)}
                label={JSON.stringify(fieldOption).replace(/"/g, "")}
                id={fieldOption + formFieldList[eachFormKey].name}
              />
              <label style={{ marginLeft: "5px" }} htmlFor={fieldOption + formFieldList[eachFormKey].name}>{fieldOption}</label>

            </li>
          ))}
        </ul>
        {/* <Col size="12">
          {fieldOptions.map(fieldOption => (
            <Input
              style={{ width: "auto", height: "auto" }}
              label={JSON.stringify(fieldOption).replace(/"/g, "")}
              type="radio"
              id={fieldOption + formFieldList[eachFormKey].name}
              key={fieldOption + formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => parentThis.onFormInputChange(e)}
              value={fieldOption}
            />
          ))}<label
            htmlFor={formFieldList[eachFormKey].name}
            id={formFieldList[eachFormKey].name}
            className="qlabel"
          >
            {JSON.stringify(formFieldList[eachFormKey].label).replace(/"/g, "")}
          </label>
        </Col> */}
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'checkbox') {
    return (
      <div className="formArea pl-2" key={eachFormKey}>
        <Col size="12">
          <Row>
            <Input
              type="checkbox"
              name={formFieldList[eachFormKey].name}
              onClick={e => parentThis.onFormInputChange(e)}

              checked={parentThis.state.formData[formFieldList[eachFormKey].name]}
              value={formFieldList[eachFormKey].name}
              className="check"
              id={formFieldList[eachFormKey].name}
              key={formFieldList[eachFormKey].name}
            />
            <label htmlFor={formFieldList[eachFormKey].name}>{formFieldList[eachFormKey].label}</label>
          </Row>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'upload') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          {`${formFieldList[eachFormKey].label}`}
          <input
            required
            type="file"
            // data-buttonText={`${formFieldList[eachFormKey].label}`}
            name={formFieldList[eachFormKey].name}
            onChange={(e) => parentThis.onFormInputChange(e)}
            // style={{content:`${formFieldList[eachFormKey].label}`}}
            // label={formFieldList[eachFormKey].label}
            // checked={parentThis.state.formData[formFieldList[eachFormKey].name]}
            // value={formFieldList[eachFormKey].name}
            // className="form-check-input radio-margin"
            id={formFieldList[eachFormKey].name}
            key={formFieldList[eachFormKey].name}
          />
          {/* <button type="submit">Submit</button> */}
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'information') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12" style={{ padding: "0", margin: "0" }}>
          <br />
          {formFieldList[eachFormKey].label}
        </Col>
      </div>
    );
  }
}
