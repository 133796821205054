import * as React from 'react';
import { Component, lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import routes from '../router/routes';
import SEO from './Utilities/Seo';
import * as variableLocalContent from "../router/variablePath.json";
const renderLoader = () => <p>Loading</p>;

let variableContent = variableLocalContent.default[Object.keys(variableLocalContent.default)[0]];

// const Navbar = lazy(() => import('./Generic Sections/Navbar'));
import Navbar from './Generic Sections/Navbar';

class Main extends Component {
  render() {
    /* Adjusts CSS of Navbar on certain routes */
    const conditionalNavbar = history => {
      if (history.location.pathname.includes("exitsurvey") || history.location.pathname === '/feedback') {
        return <Navbar navVariant="navHide" location={history.location} local={variableContent}/>;
      } else {
        return <Navbar navVariant="navSolid" location={history.location} local={variableContent}/>;
      }
    }

    return (
      <div className="App">
        <header className="App-header">
        {/* <Suspense fallback={renderLoader()}> */}
          {/* <SEO meta={variableContent.meta}/> */}
          <Route
            render={history => (
              <div>
                {conditionalNavbar(history)}
                {routes}
              </div>
            )}
          />
          {/* </Suspense> */}
        </header>
      </div>
    );
  }
}

export default Main;
