const state = {
  node: {
    type: 'Content',
    treeId: 4,
    node: 2,
    is_root: '0',
    node_name: '',
    page_title: 'Intro',
    content:
    `<div>
    <h2 style="text-align: center;"> Village Emergency Center Employee Incentive Program</h2> 
    <br />
    <br />
    <p>Please provide the following information:</p>
    <div/>`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: "",
        rank: "1",
        value: "0",
        updated: "0",
        hover_text: "",
        button_data: "0",
        button_link: "2",
        button_text: "Let's begin!",
        wizard_skip: "0",
        project_node_id: "2",
        logic_expression: ""
      },
    },
  },
  formData: {},
  formFields: {
    // '0': {
    //   name: 'failedpart',
    //   type: 'multiline',
    //   label: 'Tell us what part failed as best as you can',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '1',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    // '1': {
    //   name: 'HowInfographic',
    //   type: 'hidden',
    //   label: 'Howinfographic:',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '0',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: 'C',
    //   checkbox_score: '1',
    // },
    0: {
      name: 'fname',
      type: 'text',
      label: 'First Name:',
      inline: '0',
      scores: '',
      options: '',
      required: '1',
      score_var: '',
      label_type: 'L',
      custom_regex: '',
      hiddenValue: '',
      checkbox_score: '1',
    },
    1: {
      name: 'lname',
      type: 'text',
      label: 'Last Name:',
      inline: '0',
      scores: '',
      options: '',
      required: '1',
      score_var: '',
      label_type: 'L',
      custom_regex: '',
      hiddenValue: '',
      checkbox_score: '1',
    },
    // 2: {
    //   name: 'facility',
    //   type: 'text',
    //   label: 'Facility:',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '0',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    // '2': {
    //   name: 'phone',
    //   type: 'phone',
    //   label: 'enter your phone number',
    //   inline: '0',
    //   scores: '',
    //   options: '',
    //   required: '1',
    //   score_var: '',
    //   label_type: 'L',
    //   custom_regex: '',
    //   hiddenValue: '',
    //   checkbox_score: '1',
    // },
    2: {
      name: 'facility',
      rank: '0',
      type: 'radio',
      label: 'Facility:',
      inline: '0',
      scores: '',
      options: 'River Oaks \n Clear Creek \n Katy \n Jersey Village',
      required: '1',
      score_var: '',
      label_type: 'L',
      custom_regex: '',
      hidden_value: '',
      checkbox_score: '1',
    },
    // '6'
    // '8': {
    // '6': {
    //   name: 'TireRotation',
    //   type: 'checkbox',
    //   label: 'Tire rotation',
    // },
    // "8": {
    //                 "type": "multiline_full",
    //                 "name": "otherInfo",
    //                 "label": "Write us a note here (optional)",
    //                 "label_type": "P",
    //                 "options": "",
    //                 "hidden_value": "",
    //                 "required": "0",
    //                 "score_var": "",
    //                 "scores": "",
    //                 "rank": "0",
    //                 "checkbox_score": "1",
    //                 "custom_regex": "",
    //                 "inline": "0"
    //             }
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
