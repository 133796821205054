import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from "react-router-dom";
import Footer from '../Generic Sections/Footer';

const ThankYou = (props) => {
  const [textDisabled, setTextDisabled] = useState(false);
  const locationObject = {
    ro: 0,
    ka: 1,
    cc: 2,
    jv: 3
  }
  // console.log(props.location.state)

  // const history = useHistory();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setTextDisabled(true);
  //   }, 60000)
  // }, [textDisabled]);
  // function redirect(props) {
  //   console.log(props)
  // history.push(props.history.location.state.url);
  // }

  return (
    <div style={{ marginTop: "12vh", height: "100vh" }} className="text-center">
      <div className="mt-5 mx-4 text-center" style={{ fontSize: "30px" }}> <b>
       Thank you for visiting Swift Star Emergency Room!</b><br/><div style={{ fontSize: "18px", lineHeight: "1.4" }}> Would you take a moment to share your thoughts by leaving a review?</div><br/>
      {props.location.state
        ? <a href={props.context.locations[locationObject[props.location.state.url.substring(1, 3)]].googleLink} onClick={() => { window.dataLayer.push({ event: 'G_REVIEW' }); }}><button className="redButton">Leave a Google Review!</button></a>
        : <a href={props.context.locations[0].googleLink} onClick={() => { window.dataLayer.push({ event: 'Y_REVIEW' }); }}><button className="redButton">Leave a Google Review!</button></a>}
      <br/>
      <br/>
      {props.location.state
        ? <a href={props.context.locations[locationObject[props.location.state.url.substring(1, 3)]].yelpLink} onClick={() => { window.dataLayer.push({ event: 'Y_REVIEW' }); }}><button className="redButton">Leave a Yelp Review!</button></a>
        : <a href={props.context.locations[0].yelpLink} onClick={() => { window.dataLayer.push({ event: 'Y_REVIEW' }); }}><button className="redButton">Leave a Yelp Review!</button></a>}
      <br/>
      <br/>
      {/* {props.location.state
        ? <a href={props.context.locations[locationObject[props.location.state.url.substring(1, 3)]].fbLink} onClick={() => { window.dataLayer.push({ event: 'F_REVIEW' }); }}><button className="redButton">Leave a Facebook Review!</button></a>
        : <a href={props.context.locations[0].fbLink} onClick={() => { window.dataLayer.push({ event: 'F_REVIEW' }); }}><button className="redButton">Leave a Facebook Review!</button></a>} */}
      </div>
      {/* <img src={require(`../../images/${props.location.state.url.substring(1, 3)}qr_live.png`).default} style={{ width: 'auto', height: '20vh' }} className="img-fluid mx-auto d-block"
        alt="QR Code"/> */}
   
      <p className="mt-5 mx-4" style={{ fontSize: "18px", height: "50vh" }}>We love to help the Houston community, and leaving a review can help us continue to provide for our community. 
      {/* Leave a review on any of these platforms, show a staff member, and get a <b>$5 Starbucks giftcard!</b> */}
      </p>
      {/* <div>
        {textDisabled && <a onClick={() => redirect(props)}>Return to start</a>}
      </div> */}
      <Footer local={props.context}/>
    </div>
  );
};

export default ThankYou;
