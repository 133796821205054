import { useState, useEffect, useRef } from "react";

let SearchBox;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, SearchBoxRef, props, currentQuery) {
  // console.log(typeof window.google)
  let bounds = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(26.452662, -106.301215),
    new window.google.maps.LatLng(37.613458, -91.559271)
  )
  SearchBox = new window.google.maps.places.SearchBox(
    SearchBoxRef.current,
    {
      types: ["address"],
      componentRestrictions: { country: "us" },
      bounds
    }
  );
  // SearchBox.setFields(["address_components", "formatted_address"]);
  SearchBox.addListener("places_changed", () =>
    handlePlaceSelect(updateQuery, props, currentQuery)
  );
}

async function handlePlaceSelect(updateQuery, props, currentQuery) {
  const addressObject = SearchBox.getPlaces();
  // console.log(SearchBox.gm_accessors_.places.Hj.gm_accessors_.input.Hj.V);
  const query = addressObject.formatted_address;
  updateQuery(query);
  // console.log(addressObject[0].geometry.location.lat(), addressObject[0].geometry.location.lng());
  // let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressObject.formatted_address}&key=AIzaSyDw0uxUc4cxPpSIJsljsHsMYMf5ewBh2rg`;
  // fetch(url).then(res => {
  //     // console.log(res);
  //     return res.json();
  // })
  // .then((data) => {
  // console.log(data)
  // return data.results[0].geometry.location;
  // }).then((latLng) => {

  let typed = SearchBox?.gm_accessors_?.places?.Ej?.gm_accessors_?.input?.Ej?.V;
  props.findNearest({
    coords: {
      latitude: addressObject[0].geometry.location.lat(),
      longitude: addressObject[0].geometry.location.lng()
    }
  }, { typed: typed }).then(nearest => { props.setNearest(nearest) })
    .catch(error => console.error('Error', error));
}

function FindAddress(props) {
  const [query, setQuery] = useState("");
  const SearchBoxRef = useRef(null);

  useEffect(() => {
    let src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDw0uxUc4cxPpSIJsljsHsMYMf5ewBh2rg&libraries=places";
    console.log(document.querySelector(`script[src="${src}"]`));
    if (!document.querySelector(`script[src="${src}"]`)) {
      console.log("script is not here")
      loadScript(
                // `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
                `https://maps.googleapis.com/maps/api/js?key=AIzaSyDw0uxUc4cxPpSIJsljsHsMYMf5ewBh2rg&libraries=places`,
                () => handleScriptLoad(setQuery, SearchBoxRef, props, query)
      );
    } else {
      console.log("script is here");
      // TEMPORARY - if the script doesn't load properly, the page fails to load and will cause an error. Ensuring the script loads in fixes this.
      // handleScriptLoad(setQuery, SearchBoxRef, props, query)
      loadScript(
        // `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDw0uxUc4cxPpSIJsljsHsMYMf5ewBh2rg&libraries=places`,
        () => handleScriptLoad(setQuery, SearchBoxRef, props, query)
      );
    }
  }, []);

  return (
    <div className="search-location-input">
      <input
        style={{ width: "80%", marginBottom: "2rem", fontSize: "0.95rem" }}
        ref={SearchBoxRef}
        onChange={event => { setQuery(event.target.value) }}
        placeholder="Enter Address"
        // value={query}
      />
    </div>
  );
}

export default FindAddress;
