import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from "react-router-dom";
import Footer from '../Generic Sections/Footer';

const VisitComplete = (props) => {
  const [textDisabled, setTextDisabled] = useState(false);
  // const history = useHistory();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setTextDisabled(true);
  //   }, 60000)
  // }, [textDisabled]);
  // function redirect(props) {
  //   console.log(props)
  //   history.push(props.history.location.state.url);
  // }
  return (
    <div style={{ marginTop: "12vh", height: "100vh" }} className="text-center">
      <div className="mt-5 mx-4 text-center" style={{ fontSize: "30px" }}> <b>
       Thank you for visiting Swift Star Emergency Room.</b>
      <br/>
      </div>
      <p className="mt-5 mx-4" style={{ fontSize: "20px", height: "70vh" }}>If you felt that there was some way your experience today could have been improved, or you have questions or concerns about your visit or billing, please contact us at <a href="tel:+17135262320">(713)-526-2320.</a>
      </p>
      <div>
        {textDisabled && <a onClick={() => redirect(props)}>Return to start</a>}
      </div>
      <Footer local={props.context}/>
    </div>
  );
};

export default VisitComplete;
